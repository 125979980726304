import React,{useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Init from "./panels/Init";
import bridge from '@vkontakte/vk-bridge';
import Start from './panels/Start';
import TestPage from './panels/TestPage';
import ResultPage from './panels/ResultPage';
import {setLaunchParams} from './store/mainReducer'
import AboutPage from './panels/AboutPage';
import ServerConnect from './service'



const App = () => {

  const dispatch = useDispatch()
  const activePanel = useSelector(state => state.main.activePanel)

  useEffect(() => {
    async function fetchData() {
      const launch_params = await bridge.send("VKWebAppGetLaunchParams");
      if (launch_params) {
        return launch_params;
      }
    }
    fetchData().then(data => {dispatch(setLaunchParams(data)), ServerConnect.sendGet('/users', {...data})})
  }, [])


  let content = []
  if (activePanel === 'init') {
    content.push(<Init key='init'/>)
  } else if (activePanel === 'start') {
    content.push(<Start key='start'/>)
  } else if (activePanel === 'testpage') {
    content.push(<TestPage key='testpage'/>)
  } else if (activePanel === 'aboutpage') {
    content.push(<AboutPage key='aboutpage'/>)
  } else if (activePanel === 'resultpage') {
    content.push(<ResultPage key='resultpage'/>)
  } 

  return (
      <div className='app'>
        {content}
      </div>
  );
}
export default App;
