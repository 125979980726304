import React from 'react';
import { useEffect } from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer"


const Init = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        const loading = setTimeout(() => {
            dispatch(setActivePanel('start'))
        }, 3000);
        return () => clearTimeout(loading);
      }, []);

    return (
        <div className="container start-page">
            <a href="#" className="start-page__logo logo">
                <img width="143" height="20" src="assets/images/logo.svg" alt="" />
            </a>
            <img className="start-page__megafon-circles" src="assets/images/megafon-circles.svg" width="187" height="91" alt="" />
            <img className="start-page__sub-logo sub-logo" width="240" height="160" loading="lazy" decoding="async" src="assets/images/main-logo.png" alt="logo" />
            <div className="loading-block container__loading-block">
                <div className="loading-block__bar"></div>
            </div>
        </div>
    );
}

export default Init;

