import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import bridge from '@vkontakte/vk-bridge';
import {setActivePanel, setCurrentAboutItem} from "../store/mainReducer"
import ServerConnect from '../service'

const ResultPage = () => {
    const dispatch = useDispatch()
    const num_result = useSelector(state=>state.main.num_result)
    const launch_params = useSelector(state=>state.main.launch_params)
    const prizes = useSelector(state=>state.main.prizes)
    
    const reload = () => {
        ServerConnect.sendGet('/stats_repeat', {...launch_params})
        dispatch(setActivePanel('testpage'))
    }

    const goToAbout = (code) => {
        dispatch(setCurrentAboutItem(code))
        dispatch(setActivePanel('aboutpage'))
    }

    async function share()  {
        ServerConnect.sendGet('/stats_share_btn', {...launch_params})
        bridge.subscribe(event => {
          if (!event.detail) { return; }
          const { type, data } = event.detail;
          if (type === 'VKWebAppShowWallPostBoxResult') {
              ServerConnect.sendGet('/shared', {...launch_params})
          }
        });

        let params = {
            "owner_id": launch_params.vk_user_id,
            "message": 'Breaking News: у меня есть МегаСила, и у тебя она тоже есть! Узнай, какая, и выиграй сертификат до 100 000 рублей в shop.megafon.ru',
            "attachments": "https://vk.com/app51404835",
            "link_button": 'open_url',
            "link_image": `https://megafon.gamesmm.ru/assets/images/share-pic${num_result}.png`,
        }
        bridge.send("VKWebAppShowWallPostBox", params)
          .then(data=>{
            console.log('share')
          })
          .catch(err=>console.log(err));
    }
    
    const data = {
    1: {text: 'Шаришь за тренды и мемы', description: <>Ты директор пояснительной бригады в дружеском чате. Лучше всех рассказываешь, как работает NFT и что такое «база».<br/><br/>
Оставайся в потоке даже на даче с МегаСилами. Это полезные услуги и сервисы, которые сделают твой тариф #БезПереплат еще выгоднее. Активируй их бесплатно!</>},
      2: {text: 'Даешь советы лучше нейросетей', description: <>Друзья оформили премиум на твои рекомендации. Ты любишь кино, музыку и точно знаешь, чего хотят эти «кожаные мешки». Так ни один стриминг не умеет!<br/><br/>
Смотри и слушай больше с МегаСилами. Это полезные услуги и сервисы, которые сделают твой тариф #БезПереплат еще выгоднее. Активируй их бесплатно!</>},
      3: {text: 'Находишься в моменте и в ресурсе, но это неточно', description: <>Ты живешь жизнь на все 217%, а диджитал помогает тебе оставаться в ресурсе. Ведь каждому из нас иногда нужны гилти-песни и картинки с капибарами.<br/><br/>
Выдыхай чаще с МегаСилами. Это полезные услуги и сервисы, которые сделают твой тариф #БезПереплат еще выгоднее. Активируй их бесплатно!</>},
    }


    return (
    <div className="container result-page">
        
        <div className="result-page__image" style={{'backgroundImage': `url(assets/images/result-image${num_result}.png)`}}>
            
        </div>
        <div className="result-page__image-xl" style={{'backgroundImage': `url(assets/images/result-image-xl${num_result}.png)`}}>

        </div>
        <div className="result-page__text">
            <p className="result-page__pre-caption">Твоя мегасила:</p>
            <p className="result-page__caption">{data[num_result].text}</p>
            <p className="result-page__description">{data[num_result].description}</p>
        </div>
        <div className="powers">
            <p className="powers__caption">
                Рекомендуемые МегаСилы
            </p>
            <div className="power-items">
                {
                    prizes[num_result].map((elem, index)=>{
                        return(
                            <div className="power-item" key={index}>
                                <img src={`assets/images/${elem.picName}.png`} width="38" height="53" loading="lazy" decoding="async" alt="" />
                                <p className="power-item__caption">{elem.title}</p>
                                <button className="info-slide__href" onClick={()=>goToAbout(elem.code)}>Подробнее</button>
                            </div>
                        )
                    })
                }
                
                {/* <div className="power-item">
                    <img src="assets/images/slider-pic.png" width="38" height="53" loading="lazy" decoding="async" alt="" />
                    <p className="power-item__caption">Безлимит на музыку</p>
                </div>
                <div className="power-item">
                    <img src="assets/images/slider-pic.png" width="38" height="53" loading="lazy" decoding="async" alt="" />
                    <p className="power-item__caption">Безлимит на музыку</p>
                </div> */}
            </div>
        </div>
        <div className="result-page__buttons">
            <div className="result-page__button-item">
                <button className="result-page__reload" onClick={()=>reload()}>
                    <img src="assets/images/reload-icon.svg" width="24" height="26" alt="" />
                </button>
                <button className="button_share button_color_green button_type_share" onClick={()=>share()}>
                    <img src="assets/images/share-icon.svg" width="20" height="17" alt="" />
                    Поделиться<br/>и&nbsp;выиграть приз
                </button>
            </div>
            <div className="result-page__button-item">
                <a href="https://megafon.ru/tariffs/all/?utm_source=1-cross-vk&utm_campaign=fed_flight_www-spec-b2c_ulutschaizer_sept_oct_2022&utm_medium=ag-media_media_all_all_interests_reklamaapp_cpm&utm_content=&utm_term=_all" target="_blank" className="button button_color_purple">К МегаСилам</a>
            </div>
        </div>
    </div>
    );
}

export default ResultPage;
