import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        activePanel: 'init',
        launch_params: null,
        result_products: [],
        product_sku: '',
        user: null,
        animal: '',
        size: '',
        age: '',
        prizes: {
          1:
            [
                {
                    title: 'Интернет с pre-5G',
                    picName: 'prize1',
                    code: 'pre-5g'
                },
                {
                    title: 'Безлимит на видео',
                    picName: 'prize2',
                    code: 'unlimited-video'
                },
                {
                    title: 'Безлимит на соцсети',
                    picName: 'prize3',
                    code: 'unlimited-network'
                }
            ],
          2:
            [
                {
                    title: 'Безлимит на кинотеатры',
                    picName: 'prize4',
                    code: 'unlimited-cinema'
                },
                {
                    title: 'Онлайн‑кинотеатр START',
                    picName: 'prize5',
                    code: 'cinema'
                },
                {
                    title: 'Безлимит на музыку',
                    picName: 'prize6',
                    code: 'unlimited-music'
                }
            ],
          3:
            [
                {
                    title: 'Перенос остатков',
                    picName: 'prize7',
                    code: 'residue'
                },
                {
                    title: 'Улучшайзер тарифа',
                    picName: 'prize8',
                    code: 'tarrif'
                },
                {
                    title: 'Безлимит на музыку',
                    picName: 'prize6',
                    code: 'unlimited-music'
                }
            ],
        },
        questions: [
            {
                id: 0,
                question: 'Как ты решаешь рабочие вопросики?',
                answers: ['В зуме: всегда приятно увидеть кота Юры из отдела продаж.', 'Я за созвоны, а то еще голову мыть и штаны надевать.', 'Все вопросы в переписке, господа.'],
            },
            {
                id: 1,
                question: ' Смотришь сериалы и кино запойно?',
                answers: ['Иногда марафоню по несколько часов.', 'Пфф, это моя вторая работа.', 'Все, что было в запое, остается в запое.'],
            },
            {
                id: 2,
                question: 'Сколько пунктов в твоём кино-сериальном вишлисте?',
                answers: ['Два или девять. Сколько надо?', 'Так-так. Ни с места. С польских комедий начнем?', 'С жизнью бы сначала разобраться 🍑🔥'],
            },
            {
                id: 3,
                question: 'Как обстоят дела с апгрейдом гаджетов?',
                answers: ['Последняя ось — всем осям ось 🤓 ', 'Проще взять ипотеку, чем загрузить обновления.', 'Я прикладываю к телефону подорожник.'],
            },
            {
                id: 4,
                question: 'Друзья подарили сертификат маркетплейса. Как его потратишь?',
                answers: ['Какой сертификат? Уже запускаю новую игру на «плойке».', 'Куплю пижаму и костюм жирафа на Хэллоуин.', 'Возьму термобелье и шерстяные носки к зиме.'],
            },
            {
                id: 5,
                question: 'Как ты слушаешь музыку?',
                answers: ['Кручу треки на репите, а потом их ненавижу. Стабильность!', 'Я пылкий меломан и могу убить движением бедра.', 'По настроению. А оно от Меладзе до Фараона 🤡 '],
            },
            {
                id: 6,
                question: 'Ты ночуешь в гостинице. Wi-Fi работает плохо, а мобильный интернет не тянет YouTube. Что будешь делать?',
                answers: ['Звать на помощь: у меня первобытная паника без ютуба.', 'Ситуация Ploho и ssshhhiiittt! — эти группы я и послушаю.', 'Эээ…читать книгу. Иначе душнить будет нечем.'],
            },
            {
                id: 7,
                question: 'В скольких соцсетях ты делишься приколами?',
                answers: ['Опытный мемелье всеоружен и всемемен.', 'После двух соцсетей меня вертолетит.', 'Бабушки у подъезда — вот моя главная соцсеть.'],
            },
        ],
        num_result: null,
        currentAboutItem: ''
    },
    reducers: {
        setActivePanel(state, action) {
            state.activePanel = action.payload
        },
        setLaunchParams(state, action) {
            state.launch_params = action.payload
        },
        setUser(state,action){
            state.user = action.payload
        },
        setAnswer(state, action){
            state.answers.push(action.payload)
        },
        setNumResult(state, action) {
            state.num_result = action.payload
        },
        setCurrentAboutItem(state, action) {
            state.currentAboutItem = action.payload
        },
    }
})

export default mainSlice.reducer
export const {
    setActivePanel,
    setLaunchParams,
    setUser,
    setAnswer,
    setNumResult,
    setCurrentAboutItem
} = mainSlice.actions
