import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setActivePanel } from "../store/mainReducer"
import { Swiper, SwiperSlide, Navigation } from 'swiper/react';
import 'swiper/css';

const AboutPage = () => {
    const dispatch = useDispatch()

    const [swiperSlider, setSwiperSlider] = useState({});
    const num_result = useSelector(state=>state.main.num_result)
    const currentAboutItem = useSelector(state=>state.main.currentAboutItem) 

    const turnBackward = ()=>{
        num_result === null ? 
        dispatch(setActivePanel('start')) :
        dispatch(setActivePanel('resultpage'))
    }
    
    return (
        <div className="container func-page">
            <div className="func-page__content info-block">
                <p className="info-block__caption">О Мега Силах</p>
                <p className="info-block__description">МегаСилы — это полезные услуги и сервисы, с которыми можно выгодно прокачать свой тариф МегаФона #БезПереплат. Активируй их бесплатно!</p>
                <div className="info-slider">
                    <Swiper
                    spaceBetween={11}
                    slidesPerView={1}
                    width={211}
                    centeredSlides
                    loop
                    onInit={
                        (event) => {
                            setSwiperSlider(event)
                            if(currentAboutItem){
                                const currentSlideByTarget = document.getElementById(currentAboutItem).parentNode.getAttribute('data-swiper-slide-index')
                                console.log(window.innerWidth)
                                window.innerWidth > 660 ? 
                                event.slideTo(+currentSlideByTarget+3)
                                : event.slideTo(+currentSlideByTarget+1)
                            }
                        }
                    }
                    breakpoints={{
                        660: {
                          width: 620,
                          slidesPerView: 3,
                          spaceBetween: 20
                        },
                      }}
                    >
                        <SwiperSlide>
                            <div className="info-slide" id="cinema">
                                <img className="info-slide__image" width="92" height="108" loading="lazy" decoding="async" src="assets/images/slider-pic-start.png" alt="pic" />
                                <p className="info-slide__caption">Онлайн‑кинотеатр START</p>
                                <p className="info-slide__text">Кино и мультфильмы от известных студий, собственные сериалы, 170+ телеканалов — одна подписка для всего.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="info-slide" id="pre-5g">
                                <img className="info-slide__image" width="92" height="108" loading="lazy" decoding="async" src="assets/images/slider-pic-5g.png" alt="pic" />
                                <p className="info-slide__caption">Интернет с pre-5G</p>
                                <p className="info-slide__text">Мобильный интернет летает в полтора раза быстрее обычного. Магия!</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="info-slide" id="tarrif">
                                <img className="info-slide__image" width="92" height="108" loading="lazy" decoding="async" src="assets/images/slider-pic-tarif.png" alt="pic" />
                                <p className="info-slide__caption">Улучшайзер тарифа</p>
                                <p className="info-slide__text">С каждым месяцем тариф все выгоднее: дополнительные минуты, Гб и SMS бесплатно.</p>
                                <a href='https://www.megafon.ru/go/ut_sb' target="_blank" className="info-slide__href">Подробнее</a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="info-slide" id="residue">
                                <img className="info-slide__image" width="92" height="108" loading="lazy" decoding="async" src="assets/images/slider-pic-move.png" alt="pic" />
                                <p className="info-slide__caption">Перенос остатков</p>
                                <p className="info-slide__text">Можно потратить неиспользованные SMS, минуты и ГБ в следующем месяце.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="info-slide" id="unlimited-video">
                                <img className="info-slide__image" width="92" height="108" loading="lazy" decoding="async" src="assets/images/slider-pic-video.png" alt="pic" />
                                <p className="info-slide__caption">Безлимит на видео</p>
                                <p className="info-slide__text">Неограниченный интернет на просмотр YouTube и RuTube — залипай хоть до следующего лета.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="info-slide" id="unlimited-cinema">
                                <img className="info-slide__image" width="92" height="108" loading="lazy" decoding="async" src="assets/images/slider-pic-cinema.png" alt="pic" />
                                <p className="info-slide__caption">Безлимит на кинотеатры</p>
                                <p className="info-slide__text">В любой непонятной ситуации смотри фильмы и сериалы. Неограниченный интернет на IVI, ОККО, START, PREMIER, «Кинопоиск», «Амедиатека» — интернета хватит на все.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="info-slide" id="unlimited-music">
                                <img className="info-slide__image" width="92" height="108" loading="lazy" decoding="async" src="assets/images/slider-pic-music.png" alt="pic" />
                                <p className="info-slide__caption">Безлимит на музыку</p>
                                <p className="info-slide__text">Послушай, ты только послушай Яндекс Музыку, VK Музыку, Звук, Apple Music и YouTube Music - неограниченный интернет на использование этих приложений.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="info-slide" id="unlimited-network">
                                <img className="info-slide__image" width="92" height="108" loading="lazy" decoding="async" src="assets/images/slider-pic-social.png" alt="pic" />
                                <p className="info-slide__caption">Безлимит на соцсети</p>
                                <p className="info-slide__text">Время приколов и любви: неограниченный интернет на ВКонтакте, TikTok, Twitch, на Tinder и Одноклассники. Ни в чём себе не отказывай!</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <button className="slider-arrow slider-arrow_type_prev" onClick={()=>swiperSlider.slidePrev()}>
                        <img src="assets/images/slider-arrow.svg" width="32" height="32" alt="" />
                    </button>
                    <button className="slider-arrow slider-arrow_type_next" onClick={()=>swiperSlider.slideNext()}>
                        <img src="assets/images/slider-arrow.svg" width="32" height="32" alt="" />
                    </button>
                </div>
                <div className="info-block__buttons">
                    <a href="https://megafon.ru/tariffs/all/?utm_source=1-cross-vk&utm_campaign=fed_flight_www-spec-b2c_ulutschaizer_sept_oct_2022&utm_medium=ag-media_media_all_all_interests_reklamaapp_cpm&utm_content=&utm_term=_all" className="button button_color_purple" target="_blank">Активировать бесплатно</a>
                    <button className="button button_color_transparent" onClick={turnBackward}>Вернуться назад</button>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;
