const api_url = 'https://server1.brandbot24.ru'
import {URLSearchParams} from "core-js"

const front_url = 'https://megafon.gamesmm.ru'

async function postResource(url = '', data = {}, params={}) {
    let tail = (Object.keys(params).length === 0) ? '' : (new URLSearchParams(params)).toString()
    let request = api_url + url + '?'+ tail

    let response = await fetch(
        request,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json()
}

async function putResource(url = '', data = {}, params={}) {
    let tail = (Object.keys(params).length === 0) ? '' : (new URLSearchParams(params)).toString()
    let request = api_url + url + '?'+ tail

    let response = await fetch(
        request,
        {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить PUT запрос ${url}, статус ${response.status}`)
    }
    return await response.json()
}

async function getResource(url, params={}){

    let tail = (Object.keys(params).length === 0) ? '' : (new URLSearchParams(params)).toString()
    let request = api_url + url + '?'+ tail

    let response = await fetch(request, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        }
      });
    if (!response.ok) {
        throw new Error(`Не получилось получить данные ${url}, статус ${response.status}`)
    }
    return await response.json()
}



const ServerConnect ={
    sendPost: postResource,
    sendGet: getResource,
    sendPut: putResource,
    api_url: api_url,
    front_url: front_url
}

export default ServerConnect;
