import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setNumResult} from "../store/mainReducer"
import ServerConnect from '../service'

const TestPage = () => {
    const dispatch = useDispatch()
    const questions = useSelector(state=>state.main.questions)
    const launch_params = useSelector(state=>state.main.launch_params)
    const [currentQuestion, setQurrentQuestion] = useState(0)
    const [answers, setAnswers] = useState([0,0,0])

    const completeTest = ()=> {
        const maxValue = Math.max(...answers)
        dispatch(setNumResult(answers.indexOf(maxValue)+1))
        let params = Object.assign({...launch_params}, {result: answers.indexOf(maxValue)+1})
        ServerConnect.sendGet('/stats_result', params)
        dispatch(setActivePanel('resultpage'))
    }

    const updateQuestion = (index)=> {
        let answersUpdated = answers
        answersUpdated[index]++
        setAnswers(answersUpdated)

        questions.length > currentQuestion+1
        ? setQurrentQuestion(currentQuestion+1)
        : completeTest()
    }

    return (
        <div className="container func-page">
            <div className="func-page__content test-block">
                <div className="test-block__header">
                    <div className="question-counter">
                        <p className="question-counter__current">{currentQuestion+1}</p>
                        <p className="question-counter__amount">{questions.length}</p>
                    </div>
                    <p className="test-block__question">{questions[currentQuestion].question}</p>
                </div>
                <div className="test-block__answers">
                    {
                        questions[currentQuestion].answers.map((answer, index)=>{
                            return(
                                <button key={index} className="test-block__answer-button" onClick={()=>updateQuestion(index)}>{answer}</button>
                            )
                        })
                    }
                </div>
                <img className="test-block__sub-logo sub-logo" width="89" height="59" loading="lazy" decoding="async" src="assets/images/main-logo.png" alt="logo" />
            </div>
        </div>
    );
}

export default TestPage;
