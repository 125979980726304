import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../store/mainReducer"
import ServerConnect from '../service'


const Start = () => {
    const dispatch = useDispatch()
    const launch_params = useSelector(state=>state.main.launch_params)

    const start = () => {
        ServerConnect.sendGet('/stats_start', {...launch_params})
        dispatch(setActivePanel('testpage')) 
    }

    return (
        <div className="container func-page">
            <div className="func-page__content about-block">
                <div className="about-block__info">
                    <p className="about-block__caption">Сейчас</p>
                    <p className="about-block__question">В чем твоя МегаСила?</p>
                    <p className="about-block__description">У каждого из нас есть МегаСилы, способные менять жизнь к&nbsp;лучшему! Узнай, в&nbsp;чем твоя МегаСила, поделись результатом и&nbsp;выиграй сертификат на&nbsp;<b>10&nbsp;000, 30&nbsp;000, 50&nbsp;000 или 100&nbsp;000 рублей</b> в&nbsp;<a href="https://moscow.shop.megafon.ru/" target="_blank">shop.megafon.ru</a></p>
                </div>
                <div className="about-block__buttons">
                    <button className="button button_color_purple" onClick={()=>start()}>Я в деле!</button>
                    <button className="button button_color_transparent" onClick={()=>dispatch(setActivePanel('aboutpage'))}>О МегаСилах</button>
                    <a href="https://drive.google.com/file/d/1aCzJqo1VetNG4W_RX-rPbnqB8Azyddcl/view?usp=sharing" style={{textAlign: "center", color: "#FFF", textDecoration: "underline"}} target="_blank">Правила розыгрыша</a>
                </div>
            </div>
            <img className="func-page__sub-logo sub-logo" width="89" height="59" loading="lazy" decoding="async" src="assets/images/main-logo.png" alt="logo" />
        </div>
    );
}

export default Start;
